import { useNavigate } from 'react-router-dom';

import { PageLayout } from '../layout';

const adminReports = [
	{ name: 'Transactions Report', path: '/reports/transactions' },
	{ name: 'Card Fees Report', path: '/reports/card-fees' },
];

// const affiliateReports = [
// 	{ name: 'Commissions Report', path: '/' },

// ];

// const reports = { admin: adminReports, affiliate: affiliateReports };

export default function Reports() {
	const navigate = useNavigate();

	// const [userRole, setUserRole] = useState(null);

	// const token = localStorage.getItem('token');

	// useEffect(() => {
	// 	try {
	// 		if (token) {
	// 			const decodedToken = jwtDecode(token);
	// 			setUserRole(decodedToken.role);
	// 		}
	// 	} catch (error) {
	// 		console.error('Error decoding token:', error);
	// 		setUserRole(null); // Fallback to non-admin
	// 	}
	// }, []);

	return (
		<div>
			<PageLayout
				pageToDisplay={
					<div className="p-10">
						<div className="p-[5rem] flex justify-center space-x-10 text-white">
							{adminReports.map((report) => (
								<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center" onClick={() => navigate(report.path)}>
									{/* {<a>{userRole}</a>} */}
									<h1 className="text-2xl text-center">{report.name}</h1>
								</div>
							))}
						</div>
					</div>
				}
			/>
		</div>
	);
}
