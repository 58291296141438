import { apiRequest } from './api';

export const getSystemUsersAPI = async () => {
	try {
		const response = await apiRequest('/admin/system-users', 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const addSystemUserAPI = async (data) => {
	try {
		await apiRequest('/admin/register', 'POST', data);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
