import { apiRequest } from './api';

export const getLogsAPI = async () => {
	try {
		const response = await apiRequest('/admin/logs', 'GET', null);

		return { isSuccess: true, data: response.logs };
	} catch (error) {
		console.log(error, 'the api eror');
		return { isSuccess: false, error: error.message };
	}
};
