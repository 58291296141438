import { z } from 'zod';

export const loginSchema = z.object({
	email: z.string().email({ message: 'Email is not valid!' }),
	password: z.string().trim().min(1, { message: 'Password can not be empty!' }),
});

export const registerSchema = z
	.object({
		firstName: z
			.string()
			.trim()
			.min(1, { message: 'First name can not be empty!' })
			.regex(/^[A-Za-z]+$/, {
				message: 'First name must contains letters only',
			}),
		lastName: z
			.string()
			.trim()
			.min(1, { message: 'Last name can not be empty!' })
			.regex(/^[A-Za-z]+$/, {
				message: 'Last name must contains letters only',
			}),
		email: z.string().email({ message: 'Email is not valid!' }),
		phoneExtension: z.string().trim().min(1, { message: 'Phone extension can not be empty!' }),
		phoneNumber: z
			.string()
			.trim()
			.min(1, { message: 'Phone number can not be empty!' })
			.regex(/^\d{9,10}$/, {
				message: 'Phone number is not valid!',
			}),
		country: z.string().trim().min(1, { message: 'Country can not be empty!' }),
		type: z.string().trim().min(1, { message: 'Type can not be empty!' }),
		password: z
			.string()
			.trim()
			.min(1, { message: 'Password can not be empty!' })
			.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,12}$/, {
				message: 'Password must be 8-12 characters and contain at least one uppercase letter, one lowercase letter, one number, and one symbol.',
			}),
		confirmPassword: z.string().trim().min(1, { message: 'Confirm password can not be empty!' }),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
	});

export const TranferSchema = z.object({
	currency: z.string().trim().min(3, { message: 'Please select currency!' }),
	amount: z.number().max(1000, { message: 'Maximum per transaction is 1,000!' }).min(100, { message: 'Minimum per transaction is 100!' }),
	userWallet: z.string().trim().min(1, { message: 'Please insert user wallet!' }),
	reason: z.string().trim().min(1, { message: 'Please add a reason to transfer!' }),
});

export const SwapSchema = z.object({
	fromCurrency: z.string().trim().min(3, { message: 'Please select from currency!' }),
	amount: z.number().min(100, { message: 'Minimum per swap is 100!' }),
	toCurrency: z.string().trim().min(3, { message: 'Please select to currency!' }),
});

export const bankWithdrawSchema = z.object({
	bankCountry: z.string().trim().min(1, { message: 'Please select bank country!' }),
	bankCity: z.string().trim().min(1, { message: 'Please select bank city!' }),
	bankName: z.string().trim().min(1, { message: 'Please select bank name!' }),
	bankAddress: z.string().trim().min(1, { message: 'Please select bank address!' }),
	bankZipCode: z.string().trim().min(1, { message: 'Please select bank zipcode!' }),
	bankAccountNumber: z.string().trim().min(1, { message: 'Please select bank account number!' }),
	bankBranchCode: z.string().trim().min(1, { message: 'Please select bank branch code!' }),
	currency: z.string().trim().min(3, { message: 'Please select currency!' }),
	amount: z.number().min(1, { message: 'Please withdraw more than 0' }),
	customerName: z.string().trim().min(1, { message: 'Please select customer name!' }),
	bankSwiftOrSepa: z.string().trim().min(1, { message: 'Please select bank swift/sepa!' }),
	bankIBAN: z.string().trim().min(1, { message: 'Please select bank IBAN/Route!' }),
});
