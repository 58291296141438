import { useState, useEffect } from 'react';
import Loader from '../loader';
import { PageLayout } from '../layout';
// import { approveWithdrawTransactionAPI, sendEmailToCustomer, viewTransactionAPI, viewTransactionsAPI } from '../../api/user-api';
import { DynamicTable } from '../../components/table';

import { CustomModal } from '../../components/modal';
import { PrimaryButton } from '../../components/primary-button';
import { SecondaryButton } from '../../components/secondary-button';

import { viewTransactionAPI, viewTransactionsWithFilterAPI } from '../../services/transactions';
import { approveWithdrawTransactionAPI } from '../../services/approvals';
import { sendEmailToUserAPI } from '../../services/users';

const TABLE_HEAD = [
	{ key: 'creator', label: 'User', display: true },
	{ key: 'customerNumber', label: 'Customer Number', display: true },
	{ key: 'wireMethod', label: 'Wire Method', display: true },
	{ key: 'kind', label: 'From', display: true },
	{ key: 'amount', label: 'Charged Amount', display: true },
	{ key: 'currency', label: 'Charged Currency', display: true },
	{ key: 'exchangedCurrency', label: 'Currency To Transfer', display: true },
	{ key: 'wireType', label: 'Wire Type', display: true },
	{ key: 'status', label: 'Status', display: true },
	{ key: 'createdAt', label: 'Created Time', display: true },
];

export default function PendingWithdrawalRequests() {
	const [transactions, setTransactions] = useState([]);

	const [errMsg, setErrMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [selectedTransaction, setSelectedTransaction] = useState(null);

	const [senderName, setSenderName] = useState('Paytora LTD');
	const [senderBankName, setSenderBankName] = useState('Millennium Bank');

	const [content, setContent] = useState('');

	const [showDetailsModal, setShowDetailsModal] = useState(false);

	const [showDeclineModal, setShowDeclineModal] = useState(false);

	const [showSendEmailModal, setShowEmailModal] = useState(false);

	const getTransactionById = async (id) => {
		const response = await viewTransactionAPI(id);
		if (response.isSuccess) setSelectedTransaction(response.data);
	};

	const approveWithdraw = async (status = 'approved') => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await approveWithdrawTransactionAPI(selectedTransaction.id, { senderName, senderBankName, status });

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error);
			}

			setShowDetailsModal(false);
			window.location.reload();
		}
	};

	useEffect(() => {
		const fetchTransactions = async () => {
			if (!isLoading) {
				setIsLoading(true);
				const response = await viewTransactionsWithFilterAPI({ type: 'withdrawal', status: 'pending' });
				if (response.isSuccess) setTransactions(response.data);
			}
			setIsLoading(false);
		};
		fetchTransactions(); // Fetch all transactions initially
	}, []);

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div className="p-10">
						<h1 className="text-xl mb-10">Withdrawal Transactions</h1>
						<DynamicTable
							headers={TABLE_HEAD}
							rows={transactions}
							actions={[
								{
									label: 'Approve',
									method: async (id) => {
										// setSelectedTransactionId(id);
										await getTransactionById(id);
										setShowDetailsModal(true);
									},
								},
								{
									label: 'Decline',
									method: async (id) => {
										await getTransactionById(id);
										setShowDeclineModal(true);
									},
								},
								{
									label: 'Send Email',
									method: async (id) => {
										await getTransactionById(id);
										setShowEmailModal(true);
									},
								},
							]}
						/>

						<CustomModal
							isOpen={showSendEmailModal}
							onClose={() => setShowEmailModal(false)}
							title="Send email for requirements to customer"
							width="50%"
							children={
								<div>
									<div className="mb-5">
										Customer Name:
										<p>{selectedTransaction && selectedTransaction.creator + ' - ' + selectedTransaction.customerNumber}</p>
										Send email to the customer in to ask for more requirements for this transaction
									</div>
									Email body to send to the customer
									<textarea
										className="border border-black w-full mb-5 resize-none"
										rows="5"
										value={content}
										onChange={(e) => setContent(e.target.value)}
									/>
									<div>
										<PrimaryButton
											text="Send"
											onClick={async () => {
												const htmlContent = content.replace(/\n/g, '<br>');
												if (!isLoading) {
													setIsLoading(true);
													const response = await sendEmailToUserAPI({ customerId: selectedTransaction.creatorId, content: htmlContent });
													if (response.isSuccess) setShowEmailModal(false);
													setIsLoading(false);
												}
											}}
											className="p-5 me-2"
										/>
										<SecondaryButton text="Cancel" onClick={() => setShowEmailModal(false)} className="p-5" />
									</div>
								</div>
							}
						/>

						<CustomModal
							isOpen={showDeclineModal}
							onClose={() => setShowDeclineModal(false)}
							title="Are you sure you want to decline this transaction?"
							width="50%"
							children={
								<div>
									<div>
										Customer Name:
										<p>{selectedTransaction && selectedTransaction.creator + ' - ' + selectedTransaction.customerNumber}</p>
										You are about to decline this transactions <br /> The money will be refund back to the customer
									</div>
									<div>
										<p className="text-red-500 m-2">{errMsg}</p>
										<PrimaryButton text="Approve" onClick={async () => await approveWithdraw('declined')} className="p-5 me-2" />
										<SecondaryButton text="Cancel" onClick={() => setShowDeclineModal(false)} className="p-5" />
									</div>
								</div>
							}
						/>

						<CustomModal
							isOpen={showDetailsModal}
							onClose={() => setShowDetailsModal(false)}
							title="Are you sure you want to approve this transaction?"
							width="50%"
							children={
								<div className="flex flex-col items-center justify-center mt-5">
									<div className="pb-10">
										<div>
											<div className="flex justify-center">
												<p className="font-bold me-2">Customer Name:</p>
												<p>{selectedTransaction && selectedTransaction.creator + ' - ' + selectedTransaction.customerNumber}</p>
											</div>
											<div className="flex justify-center">
												<p className="font-bold me-2">Amount Paid:</p>
												<p>
													{selectedTransaction && selectedTransaction.amount - selectedTransaction.revenue + selectedTransaction.currency.symbol} (+
													{selectedTransaction && selectedTransaction.revenue.toFixed(2) + selectedTransaction.currency.symbol} fees) <br />
													<b>Total - {selectedTransaction && selectedTransaction.amount + selectedTransaction.currency.symbol}</b>
												</p>
											</div>
										</div>
										<h1 className="text-2xl underline text-center mb-2 mt-2">Proof Of Payment (POP)</h1>
										<h1 className="text-xl underline">Transaction Details</h1>
										<div className="flex">
											<p className="font-bold me-2">Customer Name:</p>
											<p>{selectedTransaction && selectedTransaction.creator}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">Requested Amount To Transfer:</p>
											<p>
												{selectedTransaction && (selectedTransaction.toAmount ?? selectedTransaction.amount - selectedTransaction.revenue)}
												{selectedTransaction &&
													(selectedTransaction.toCurrency ? ' ' + selectedTransaction.toCurrency.name : ' ' + selectedTransaction.currency.name)}
											</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">Transfered from:</p>
											<p>{selectedTransaction && selectedTransaction.paymentMethod}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">Transfer Reason:</p>
											<p>{selectedTransaction && selectedTransaction.description}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">Wire Type:</p>
											<p>{selectedTransaction && selectedTransaction.wireType}</p>
										</div>

										<h1 className="text-xl underline mt-5 mb-2">Bank Details</h1>
										<div className="flex">
											<p className="font-bold me-2">Receiver Name:</p>
											<p>{selectedTransaction && selectedTransaction.receiverFullName}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">BANK NAME:</p>
											<p>{selectedTransaction && selectedTransaction.bankName}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">BANK BRANCH:</p>
											<p>{selectedTransaction && selectedTransaction.bankBranch}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">IBAN:</p>
											<p>{selectedTransaction && selectedTransaction.receiverIBAN}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">BIC:</p>
											<p>{selectedTransaction && selectedTransaction.receiverBIC}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">Bank Postal Code:</p>
											<p>{selectedTransaction && selectedTransaction.receiverPostalCode}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">Bank Street & Number:</p>
											<p>{selectedTransaction && selectedTransaction.receiverStreetAndNumber}</p>
										</div>
										<div className="flex">
											<p className="font-bold me-2">Bank City:</p>
											<p>{selectedTransaction && selectedTransaction.receiverCity}</p>
										</div>
										<h1 className="text-xl underline mt-5 mb-2">Send Details</h1>
										<div className="flex">
											<p className="font-bold me-2">Sender Name:</p>
											<input
												type="text"
												id="senderName"
												value={senderName}
												onChange={(event) => {
													setSenderName(event.target.value);
												}}
												required
												className="border-2 border-black"
											/>
										</div>
										<div className="flex">
											<p className="font-bold me-2">Sender Bank Name:</p>
											<input
												type="text"
												id="senderBankName"
												value={senderBankName}
												onChange={(event) => {
													setSenderBankName(event.target.value);
												}}
												required
												className="border-2 border-black"
											/>
										</div>
										<p className="text-md text-red-500 font-bolder">
											** Please be aware that after approval and email with the details above will be sent to the customer!
										</p>
									</div>
									<div>
										<p className="text-red-500 m-2">{errMsg}</p>
										<PrimaryButton text="Approve" onClick={async () => await approveWithdraw()} className="p-5 me-2" />
										<SecondaryButton text="Cancel" onClick={() => setShowDetailsModal(false)} className="p-5" />
									</div>
								</div>
							}
						/>
					</div>
				)
			}
		/>
	);
}
