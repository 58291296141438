import React, { useEffect, useState } from 'react';
import { DynamicTable } from '../../components/table';
import { PageLayout } from '../layout';
import { PrimaryButton } from '../../components/primary-button';
import { CustomModal } from '../../components/modal';
import { addAffiliateAPI, getAffiliatesAPI } from '../../services/affiliates';
import Loader from '../loader';

const TABLE_HEAD = [
	{ key: 'firstName', label: 'First Name', display: true },
	{ key: 'lastName', label: 'Last Name', display: true },
	{ key: 'affiliateCode', label: 'Affiliate Code', display: true },
	{ key: 'gwMerchantId', label: 'Merchant ID', display: true },
	{ key: 'affUrl', label: 'Affiliate URL', display: true },
];

const Affiliates = () => {
	const [affiliates, setAffiliates] = useState([]);

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		affiliateCode: '',
		gwMerchantId: '',
	});
	const [showPopup, setShowPopup] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!isLoading) {
			setIsLoading(true);
			const response = await addAffiliateAPI(formData);
			if (response.isSuccess) {
				setShowPopup(false);
				window.location.reload();
			} else {
				setErrMsg(response.error ?? 'Internal Error');
			}
		}
		setIsLoading(false);
	};

	const getAffiliates = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await getAffiliatesAPI();
			if (response.isSuccess) {
				const affiliateWithUrls = response.data.map((affs) => {
					return { ...affs, affUrl: 'https://qr-ew.com/register?affiliateCode=' + affs.affiliateCode };
				});

				setAffiliates(affiliateWithUrls);
			}
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getAffiliates(); // Fetch all transactions initially
	}, []);

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div>
						<div className="p-10">
							<h1 className="text-xl mb-10">Affiliates</h1>
							<div className="flex mb-10">
								<PrimaryButton
									className="p-5"
									text="Add new affiliate"
									onClick={() => {
										setShowPopup(true);
									}}
								/>
							</div>
							<DynamicTable headers={TABLE_HEAD} rows={affiliates} />
						</div>
						<CustomModal
							isOpen={showPopup}
							onClose={() => setShowPopup(false)}
							title="Add new affiliate"
							children={
								<div>
									<form onSubmit={handleSubmit} className="text-black flex flex-col justify-center">
										<label htmlFor="firstName">First Name </label>
										<input
											type="text"
											id="firstName"
											// rows="firstName"
											value={formData.firstName}
											onChange={(event) => {
												setFormData((data) => ({ ...data, firstName: event.target.value }));
											}}
											// placeholder="First Name"
											required
											className="border-2 border-black"
										/>
										<label htmlFor="lastName">Last Name </label>
										<input
											type="text"
											// rows="lastName"
											id="lastName"
											value={formData.lastName}
											onChange={(event) => {
												setFormData((data) => ({ ...data, lastName: event.target.value }));
											}}
											// placeholder="Last Name"
											required
											className="border-2 border-black"
										/>

										<label>Affiliate Code </label>
										<input
											type="text"
											rows="affiliateCode"
											value={formData.affiliateCode}
											// onChange={handleChange}
											onChange={(event) => {
												setFormData((data) => ({ ...data, affiliateCode: event.target.value }));
											}}
											// placeholder="Affiliate Code"
											className="border-2 border-black"
											required
										/>
										<label>Gateway Merchant Id</label>
										<input
											type="text"
											rows="merchantId"
											value={formData.gwMerchantId}
											onChange={(event) => {
												setFormData((data) => ({ ...data, gwMerchantId: event.target.value }));
											}}
											// placeholder="Merchant ID"
											className="border-2 border-black"
											required
										/>

										<div className="flex justify-between mt-10">
											<button
												type="submit"
												className={`bg-purple active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 rounded-xl text-white text-lg font-bold max-w-md p-5`}
											>
												<span> Submit </span>
											</button>

											<PrimaryButton
												className="p-5 !bg-black"
												text="Cancel"
												onClick={() => {
													setShowPopup(false);
												}}
											/>
										</div>
									</form>
									{errMsg && <p className="text-red-500">{errMsg}</p>}
								</div>
							}
						/>
					</div>
				)
			}
		/>
	);
};
export default Affiliates;
