import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageLayout } from '../layout';
import { DynamicTable } from '../../components/table';
import { resetPincodeAPI, ubanSDKUserAPI, updateAffiliateAPI, updateUserStatusAPI, viewUserAPI } from '../../services/users';
import { CustomModal } from '../../components/modal';
import { PrimaryButton } from '../../components/primary-button';
import { SecondaryButton } from '../../components/secondary-button';
import { getAffiliatesAPI } from '../../services/affiliates';
import Loader from '../loader';

const TRANSACTION_TABLE_HEAD = [
	{ key: 'type', label: 'Type', display: true },
	{ key: 'amount', label: 'Amount', display: true },
	{ key: 'currency', label: 'Currency', display: true },
	{ key: 'exchangedAmount', label: 'Ex Amount', display: true },
	{ key: 'exchangedCurrency', label: 'Ex Currency', display: true },
	// { key: 'exchangeRate', label: 'Exchange Rate', display: true },
	{ key: 'kind', label: 'Kind', display: true },
	{ key: 'paymentMethod', label: 'Payment Method', display: true },
	{ key: 'fromCard', label: 'From Card', display: true },
	{ key: 'toCard', label: 'To Card', display: true },
	{ key: 'status', label: 'Status', display: true },
	{ key: 'description', label: 'Description', display: true },
	{ key: 'verestroId', label: 'Verestro ID', display: true },
	{ key: 'creator', label: 'Creator', display: true },
	{ key: 'receiver', label: 'Receiver', display: true },
	{ key: 'createdAt', label: 'Created Time', display: true },
];

const MESSAGES_TABLE_HEAD = [
	{ key: 'sender', label: 'From', display: true },
	{ key: 'content', label: 'Message', display: true },
	{ key: 'isRead', label: 'Read?', display: true },
	{ key: 'type', label: 'Type', display: true },
	{ key: 'createdAt', label: 'Created Time', display: true },
];

const LOGS_TABLE_HEAD = [
	{ key: 'status', label: 'Status', display: true }, //
	{ key: 'type', label: 'Type', display: true }, //
	{ key: 'path', label: 'Path', display: true }, //
	{ key: 'error', label: 'Error', display: true }, //
	{ key: 'comment', label: 'Comment', display: true }, //
	// { key: 'user', label: 'User', display: true }, //
	{ key: 'requestBody', label: 'Request Body', display: true }, //
	{ key: 'createdAt', label: 'Created Time', display: true },
];

export default function ViewUser() {
	const { id } = useParams();
	const [user, setUser] = useState([]);
	const [kyc, setKyc] = useState({});
	const [cards, setCards] = useState([]);
	const [transactions, setTransactions] = useState([]);
	const [messages, setMessages] = useState([]);
	const [logs, setLogs] = useState([]);
	const [wallets, setWallets] = useState([]);
	const [affiliates, setAffiliates] = useState([]);

	const [userAffiliate, setUserAffiliate] = useState(null);

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const [showConfirmBanModal, setShowConfirmBanModal] = useState(false);

	const [showStatusConfirmModal, setShowStatusConfirmModal] = useState(false);

	const [showEditModal, setShowEditModal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [showReport, setShowReport] = useState('transactions');

	useEffect(() => {
		const fetchData = async () => {
			if (!isLoading) {
				setIsLoading(true);

				const response = await viewUserAPI(id);

				const affiliatesResponse = await getAffiliatesAPI();
				if (response.isSuccess) {
					setUser(response.userData.profile);
					setKyc(response.userData.kyc);
					setCards(response.userData.cards);
					setTransactions(response.userData.transactions);
					setMessages(response.userData.messages);
					setLogs(response.userData.logs);
					setWallets(response.userData.wallets);
					if (response.userData.profile.affiliate) setUserAffiliate(response.userData.profile.affiliate._id);
				}
				setAffiliates(affiliatesResponse.data);
			}
			setIsLoading(false);
		};

		fetchData();
	}, [id]);

	const resetPincode = async () => {
		const response = await resetPincodeAPI(id);
		if (response.isSuccess) window.location.reload();
	};

	const ubanSDKUser = async () => {
		const response = await ubanSDKUserAPI(user.sdkFinanceUserId);
		if (response.isSuccess) window.location.reload();
	};

	const updateUserStatus = async () => {
		const activate = user.status === 'active' ? false : true;
		const response = await updateUserStatusAPI(id, activate);
		if (response.isSuccess) window.location.reload();
	};

	const updateAffiliate = async () => {
		const response = await updateAffiliateAPI(id, userAffiliate);
		if (response.isSuccess) window.location.reload();
	};

	const actions = [
		{ title: 'Edit Affiliate', action: () => setShowEditModal(true) },
		{ title: 'Unban SDK User', action: () => setShowConfirmBanModal(true) },
		{ title: 'Reset Pincode', action: () => setShowConfirmModal(true) },
		{ title: user && user.status === 'active' ? 'Inactive' : 'Active', action: () => setShowStatusConfirmModal(true) },
	];

	const reports = [
		{ title: 'Transactions', action: () => setShowReport('transactions') },
		{ title: 'Messages', action: () => setShowReport('messages') },
		{ title: 'Logs', action: () => setShowReport('logs') },
	];

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div className="p-5">
						<div className="flex justify-end border-black border-solid">
							{actions.map((action) => (
								<button className={`bg-purple text-white p-2 m-5 rounded-lg ${action.title === 'Inactive' && 'bg-red-500'}`} onClick={action.action}>
									{action.title}
								</button>
							))}
						</div>
						<h1 className="text-2xl font-bolder mt-5">User Details</h1>
						<div className="bg-purple1 border-[20px] border-purple p-5 border-b border-black border-solid">
							<div className="flex justify-between">
								<div>
									<div className="flex flex-col">
										<p className="font-bolder">Full Name</p>
										{(user.firstName ?? '-') + ' ' + (user.lastName ?? '-')}
									</div>
									<div className="flex flex-col">
										<p className="font-bolder">Customer Number</p>
										{user.customerNumber}
									</div>
									<div className="flex flex-col">
										<p className="font-bolder">User Status</p>
										<p className={`uppercase font-bolder ${user.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>{user.status}</p>
									</div>
								</div>

								<div>
									<div className="flex flex-col">
										<p className="font-bolder">Login Email</p>
										{user.email}
									</div>
									<div className="flex flex-col">
										<p className="font-bolder">Phone Number</p>
										{user.phoneNumber ?? '---'}
									</div>
									<div className="flex flex-col">
										<p className="font-bolder">User Type</p>
										{user.type}
									</div>
								</div>
								<div>
									<div className="flex flex-col">
										<p className="font-bolder">KYC Status</p>
										{user.kycLevel}
									</div>
									<div className="flex flex-col">
										<p className="font-bolder">Verestro UID</p>
										{user.verestroUserId ?? '--'}
									</div>
									<div className="flex flex-col">
										<p className="font-bolder">IBAN ID</p>
										{user.primaryIBANr ?? '--'}
									</div>
									{user.affiliate && (
										<div className="flex flex-col">
											<p className="font-bolder">Affiliate</p>
											{user.affiliate.affiliateCode}
										</div>
									)}

									<div className="flex flex-col">
										<p className="font-bolder">Pincode Setted?</p>
										{(user.pincode !== null).toString()}
									</div>
								</div>
							</div>
						</div>
						{/*    riskLvl: String,
						verestroVerificationId: String, */}
						<h1 className="text-2xl font-bolder mt-5">KYC Details</h1>
						{kyc ? (
							<div className="bg-purple1 border-[20px] border-purple p-5 border-b border-black border-solid">
								<div className="flex justify-between">
									<div>
										<div className="flex flex-col">
											<p className="font-bolder">Country</p>
											{kyc.country ?? '---'}
										</div>
										<div className="flex flex-col">
											<p className="font-bolder">Street & Number & Apartment</p>
											{kyc.street ?? '---'} - {kyc.number ?? '---'} / {kyc.apartment ?? '---'}
										</div>
										<div className="flex flex-col">
											<p className="font-bolder">City & Postal Code</p>
											{kyc.city ?? '---'} - {kyc.postalCode ?? '---'}
										</div>
									</div>

									<div>
										<div className="flex flex-col">
											<p className="font-bolder">Document Type</p>
											{kyc.documentType ?? '---'}
										</div>
										<div className="flex flex-col">
											<p className="font-bolder">Document Number</p>
											{kyc.documentNumber ?? '---'}
										</div>
										<div className="flex flex-col">
											<p className="font-bolder">Document Expiry Date</p>
											{kyc.documentExpiryDate ?? '---'}
										</div>
									</div>
									<div>
										<div className="flex flex-col">
											<p className="font-bolder">Risk LVL</p>
											{kyc.riskLvl ?? '---'}
										</div>
										<div className="flex flex-col">
											<p className="font-bolder">Verestro Verification Id</p>
											{kyc.verestroVerificationId ?? '--'}
										</div>
									</div>
								</div>
							</div>
						) : (
							<p className="p-5">The user require to start KYC</p>
						)}
						<div className="p-5">
							<h1 className="text-2xl font-bolder">User Cards</h1>
							<div className="flex p-10">
								{cards.length > 0
									? cards.map((card) => {
											return (
												<div key={card.id} className="border-2 border-black border-1 p-5 rounded-lg me-5">
													<p>
														<b>Card Number</b> {card.pan}
													</p>
													{/* <p>Name On Card {card.nameOnCard}</p> */}
													<p>
														<b>Card Type </b>
														{card.cardTemplate.type}
													</p>
													<p>
														<b>Card Status</b> {card.status}
													</p>
													<p>
														<b>Expiry</b> {card.expiration}
													</p>
													<p>
														<b>Currency</b> {card.currency.name}
													</p>
												</div>
											);
										})
									: "User didn't applied for cards yet."}
							</div>
							<h1 className="text-2xl font-bolder">User SDK Wallets</h1>
							<div className="flex p-10">
								{wallets
									? wallets.map((wallet) => {
											return (
												<div key={wallet.id} className="border-2 border-black border-1 p-5 rounded-lg me-5">
													<p>Wallet Name: {wallet.name}</p>
													<p>Wallet Currency: {wallet.currency}</p>
													<p>SDK Finance ID: {wallet.sdkFinanceId}</p>
													<p>Available Amount: {wallet.availableAmount}</p>
												</div>
											);
										})
									: []}
							</div>
						</div>
						<div>
							{reports.map((action) => (
								<button
									className={`${action.title.toLowerCase() === showReport && 'bg-bluePurple'} bg-purple text-white p-2 m-5 rounded-lg ${action.title === 'Inactive' && 'bg-red-500'}`}
									onClick={action.action}
								>
									<p className={`${action.title.toLowerCase() === showReport && 'font-bolder'}`}>{action.title}</p>
								</button>
							))}
						</div>
						<div className="p-5">
							{showReport === 'transactions' && (
								<>
									<h1 className="text-2xl font-bolder">Transactions</h1>

									{transactions.length > 0 ? (
										<DynamicTable headers={TRANSACTION_TABLE_HEAD} rows={transactions} />
									) : (
										<p className="text-center mb-5">The user doesn't have tranasctions yet.</p>
									)}
								</>
							)}
							{showReport === 'messages' && (
								<>
									<h1 className="text-2xl font-bolder">Messages</h1>

									{messages.length > 0 ? (
										<DynamicTable headers={MESSAGES_TABLE_HEAD} rows={messages} />
									) : (
										<p className="text-center mb-5">The user doest not have messages yet.</p>
									)}
								</>
							)}
							{showReport === 'logs' && (
								<>
									<h1 className="text-2xl font-bolder">Logs</h1>

									{logs.length > 0 ? (
										<DynamicTable headers={LOGS_TABLE_HEAD} rows={logs} />
									) : (
										<p className="text-center mb-5">The user doest not have logs yet.</p>
									)}
								</>
							)}
						</div>
						<CustomModal
							isOpen={showEditModal}
							onClose={() => setShowEditModal(false)}
							title="Edit Affiliate"
							children={
								<div>
									<form
										onSubmit={async (e) => {
											e.preventDefault();
											console.log(userAffiliate, ' the user affiliate value');
											await updateAffiliate();
										}}
										className="text-black flex flex-col justify-center"
									>
										<label>Affiliate</label>

										<select
											value={userAffiliate || ''}
											onChange={(e) => {
												const value = e.target.value === '' ? null : e.target.value;
												setUserAffiliate(value);
											}}
											className="border border-black p-2"
										>
											<option value="">No Affiliate</option>
											{affiliates.map((affiliate, index) => (
												<option value={affiliate._id} key={index}>
													{affiliate.affiliateCode + ' ' + affiliate.gwMerchantId}
												</option>
											))}
										</select>

										<div className="flex justify-between mt-10">
											<button
												type="submit"
												className={`bg-purple active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 rounded-xl text-white text-lg font-bold max-w-md p-5`}
											>
												<span> Submit </span>
											</button>
											<PrimaryButton
												className="p-5 !bg-bluePurple"
												text="Cancel"
												onClick={() => {
													setShowEditModal(false);
												}}
											/>
										</div>
									</form>
								</div>
							}
						/>
						<CustomModal
							isOpen={showConfirmBanModal}
							onClose={() => setShowConfirmModal(false)}
							title="UBAN User"
							children={
								<div className="flex flex-col items-center p-5">
									<p className="text-center">Are you sure you want to uban sdk user?</p>
									<PrimaryButton text="Confirm" onClick={ubanSDKUser} className="p-5 mt-5" />
									<SecondaryButton text="Close" onClick={() => setShowConfirmBanModal(false)} className="p-5 mt-5" />
								</div>
							}
						/>
						<CustomModal
							isOpen={showConfirmModal}
							onClose={() => setShowConfirmModal(false)}
							title="Reset Customer Pincode"
							children={
								<div className="flex flex-col items-center p-5">
									<p className="text-center">Are you sure you want to reset this customer pincode?</p>
									<PrimaryButton text="Confirm" onClick={resetPincode} className="p-5 mt-5" />
									<SecondaryButton text="Close" onClick={() => setShowConfirmModal(false)} className="p-5 mt-5" />
								</div>
							}
						/>
						<CustomModal
							isOpen={showStatusConfirmModal}
							onClose={() => setShowStatusConfirmModal(false)}
							title={`${user.status === 'active' ? 'Inactivate' : 'Activated'} Customer`}
							children={
								<div className="flex flex-col items-center p-5">
									<p className="text-center">Are you sure you want to {user.status === 'active' ? 'Inactivate' : 'Activated'} this customer?</p>
									<PrimaryButton text="Confirm" onClick={updateUserStatus} className="p-5 mt-5" />
									<SecondaryButton text="Close" onClick={() => setShowStatusConfirmModal(false)} className="p-5 mt-5" />
								</div>
							}
						/>
					</div>
				)
			}
		/>
	);
}
