import { jwtDecode } from 'jwt-decode';
import { PageLayout } from '../layout';
import { useEffect, useState } from 'react';
import { getDashboardData } from '../../services/dashboard';
import Loader from '../loader';

export default function Dashboard() {
	const token = localStorage.getItem('token');
	const decodedToken = jwtDecode(token);
	const userRole = decodedToken.role;

	const [isLoading, setIsLoading] = useState(false);

	const [dashboardData, setDashboardData] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			if (!isLoading) {
				setIsLoading(true);
				const response = await getDashboardData();
				if (response.isSuccess) {
					setDashboardData(response.data);
				} else {
					console.log(response.error, ' the error');
				}
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<div>
			<PageLayout
				pageToDisplay={
					isLoading ? (
						<Loader />
					) : (
						<div className="p-10">
							<h1 className="text-2xl">Welcome {userRole}</h1>
							<div className="p-[5rem] flex justify-center space-x-10 text-white">
								<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center">
									<h1 className="text-2xl text-center">Total Users</h1> <p className="text-center text-xl">{dashboardData.users}</p>
								</div>

								<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center">
									<h1 className="text-2xl text-center">Total Paytora Monthly</h1>{' '}
									<p className="text-center text-xl">€{dashboardData.paytora_monthly}</p>
								</div>

								<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center">
									<h1 className="text-2xl text-center">Total Card Monthly</h1> <p className="text-center text-xl">€{dashboardData.card_monthly}</p>
								</div>
							</div>
							<div className="p-[5rem] flex justify-center space-x-10 text-white">
								<div className="w-[24rem] p-10 bg-purple flex flex-col items-center justify-center">
									<h1 className="text-2xl text-center">Total Google/Apple Pay</h1>{' '}
									<p className="text-center text-xl">€{dashboardData.apple_google_pay}</p>
								</div>

								<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center">
									<h1 className="text-2xl text-center">Total Card Deposit</h1> <p className="text-center text-xl">€{dashboardData.card_deposit}</p>
								</div>

								<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center">
									<h1 className="text-2xl text-center">Total ATM Withdraw</h1> <p className="text-center text-xl">€{dashboardData.atm_withdraw}</p>
								</div>
							</div>
						</div>
					)
				}
			/>
		</div>
	);
}
