import { apiRequest } from './api';

export const getDashboardData = async () => {
	try {
		const response = await apiRequest('/admin/dashboard', 'GET', null);

		return { isSuccess: true, data: response.dashboard };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
