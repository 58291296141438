import React, { useEffect, useState } from 'react';
import { DynamicTable } from '../../components/table';
import { PageLayout } from '../layout';
import { PrimaryButton } from '../../components/primary-button';
import { addSystemUserAPI, getSystemUsersAPI } from '../../services/systemUsers';
import { getAffiliatesAPI } from '../../services/affiliates';
import { CustomModal } from '../../components/modal';

const TABLE_HEAD = [
	{ key: 'email', label: 'Email', display: true },
	{ key: 'role', label: 'Role', display: true },
	{ key: 'commissions.card_deposit_c', label: `Card Deposit Commission`, display: true },
	{ key: 'commissions.atm_withdraw_c', label: `ATM Commission`, display: true },
	{ key: 'commissions.apple_google_pay_c', label: `Apple/Google Pay Commission`, display: true },
	{ key: 'commissions.paytora_monthly_c', label: `Paytora Monthly Commission`, display: true },
	{ key: 'commissions.card_monthly_c', label: `Card Monthly Commission`, display: true },
];

const SystemUsers = () => {
	const [systemUsers, setSystemUsers] = useState([]);
	const [affiliates, setAffiliates] = useState([]);

	const [errMsg, setErrMsg] = useState('');

	const [showPopup, setShowPopup] = useState(false);

	const [formData, setFormData] = useState({
		email: '',
		role: 'affiliate',
		password: '',
		confirmPassword: '',
		affiliate: affiliates[0],
	});

	const getSystemUsers = async () => {
		const response = await getSystemUsersAPI();
		if (response.isSuccess) setSystemUsers(response.data);
	};

	const addSystemUser = async (e) => {
		e.preventDefault();
		const response = await addSystemUserAPI(formData);
		if (!response.isSuccess) return setErrMsg(response.error);
		setShowPopup(false);
	};

	useEffect(() => {
		const fetchAffiliates = async () => {
			const affiliatesResponse = await getAffiliatesAPI();
			if (affiliatesResponse.isSuccess) {
				setAffiliates(affiliatesResponse.data);
			}
		};
		getSystemUsers();
		fetchAffiliates();
	}, []);

	return (
		<PageLayout
			pageToDisplay={
				<div>
					<div className="p-10">
						<h1 className="text-xl mb-10">System Users</h1>
						<div className="flex mb-10">
							<PrimaryButton className="p-5" text="Add new system user" onClick={() => setShowPopup(true)} />
						</div>
						<DynamicTable headers={TABLE_HEAD} rows={systemUsers} />
					</div>

					<CustomModal
						isOpen={showPopup}
						onClose={() => setShowPopup(false)}
						title="Add new system user"
						children={
							<div>
								<form onSubmit={addSystemUser} className="text-black flex flex-col justify-center">
									<label htmlFor="email">Email </label>
									<input
										type="email"
										id="email"
										value={formData.email}
										onChange={(event) => {
											setFormData((data) => ({ ...data, email: event.target.value }));
										}}
										required
										className="border-2 border-black"
									/>
									<label htmlFor="password">Password </label>
									<input
										type="text"
										id="password"
										value={formData.password}
										onChange={(event) => {
											setFormData((data) => ({ ...data, password: event.target.value }));
										}}
										required
										className="border-2 border-black"
									/>
									<label htmlFor="confirmPassword">Confirm Password </label>
									<input
										type="text"
										id="confirmPassword"
										value={formData.confirmPassword}
										onChange={(event) => {
											setFormData((data) => ({ ...data, confirmPassword: event.target.value }));
										}}
										required
										className="border-2 border-black"
									/>
									<label htmlFor="lastName">Role </label>
									<select
										value={formData.role}
										onChange={(e) => setFormData((data) => ({ ...data, role: e.target.value }))}
										className="border border-black p-2"
									>
										<option value="affiliate">Affiliate</option>
										<option value="admin">Admin</option>
									</select>

									{formData.role === 'affiliate' && (
										<>
											<label htmlFor="lastName">Affiliate </label>
											<select
												value={formData.affiliate}
												onChange={(e) => setFormData((data) => ({ ...data, affiliate: e.target.value }))}
												className="border border-black p-2"
											>
												{affiliates.map((affiliate, index) => (
													<option value={affiliate._id} key={index}>
														{affiliate.affiliateCode + ' ' + affiliate.gwMerchantId}
													</option>
												))}
											</select>
										</>
									)}

									<div className="flex justify-between mt-10">
										<button
											type="submit"
											className={`bg-purple active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out py-3 rounded-xl text-white text-lg font-bold max-w-md p-5`}
										>
											<span> Submit </span>
										</button>

										<PrimaryButton
											className="p-5 !bg-black"
											text="Cancel"
											onClick={() => {
												setShowPopup(false);
											}}
										/>
									</div>
								</form>
								{errMsg && <p className="text-red-500">{errMsg}</p>}
							</div>
						}
					/>
				</div>
			}
		/>
	);
};
export default SystemUsers;
