// import { deliverCardOrderAPI, getPhysicalCardOrdersAPI } from '../../api/user-api';
import { useEffect, useState } from 'react';
import { PageLayout } from '../layout';
import { DynamicTable } from '../../components/table';
import { CustomModal } from '../../components/modal';
import { PrimaryButton } from '../../components/primary-button';
import { SecondaryButton } from '../../components/secondary-button';
import Loader from '../loader';
import { deliverCardOrderAPI, getPhysicalCardOrdersAPI } from '../../services/cards';

const TABLE_HEAD = [
	{ key: 'createdAt', label: 'Created Time', display: true },
	{ key: 'deliveryDate', label: 'Delivered Date', display: true },
	{ key: 'user', label: 'User', display: true },
	{ key: 'customerNumber', label: 'Customer Number', display: true },
	{ key: 'cardLastDigits', label: 'Last 4 digits', display: true },
	{ key: 'cardType', label: 'Card Limit', display: true },
	{ key: 'status', label: 'Status', display: true },
	{ key: 'shippingMethod', label: 'Shipping Method', display: true },
	{ key: 'city', label: 'City', display: true },
	{ key: 'street', label: 'Street', display: true },
	{ key: 'houseNumber', label: 'House Number', display: true },
	{ key: 'zipcode', label: 'Zip Code', display: true },
	{ key: 'country', label: 'Country', display: true },
];

export default function PhysicalCardOrders() {
	const [orders, setOrders] = useState([]);

	const [showApproveModal, setShowApproveModal] = useState(false);

	const [currentOrderId, setCurrentOrderId] = useState(null);

	const [errMsg, setErrMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const deliverCardOrder = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await deliverCardOrderAPI(currentOrderId);
			if (response.error) {
				setErrMsg(response.error);
				setShowApproveModal(false);
				setIsLoading(false);
				return;
			}
			setShowApproveModal(false);
			setIsLoading(false);
			window.location.reload();
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			if (!isLoading) {
				setIsLoading(true);
				const response = await getPhysicalCardOrdersAPI();
				if (response.isSuccess) {
					setOrders(response.orders);
				}
			}
			setIsLoading(false);
		};

		fetchData();
	}, []);

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div className="p-10">
						<h1 className="text-xl mb-10">Physical Card Orders</h1>
						{
							<DynamicTable
								headers={TABLE_HEAD}
								rows={orders}
								actions={[
									{
										label: 'Deliver',
										method: (id) => {
											setCurrentOrderId(id);
											setShowApproveModal(true);
										},
									},
								]}
							/>
						}

						<CustomModal
							isOpen={showApproveModal}
							onClose={() => setShowApproveModal(false)}
							title="Are you sure you want to deliver this order?"
							children={
								<div className="flex justify-center mt-5">
									<PrimaryButton text="Approve" onClick={() => deliverCardOrder()} className="p-5 me-2" />
									<SecondaryButton text="Cancel" onClick={() => setShowApproveModal(false)} className="p-5" />
									{errMsg && <p className="text-red-500">{errMsg}</p>}
								</div>
							}
						/>
					</div>
				)
			}
		/>
	);
}
