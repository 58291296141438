import { apiRequest } from './api';

export const loginAPI = async (email, password) => {
	try {
		const response = await apiRequest('/admin/auth', 'POST', { email, password });

		return { isSuccess: true, token: response.token };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
