import { useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import Dashboard from './pages/dashbaord/dashboard';
import Users from './pages/users/users';
import ViewUser from './pages/users/view-user';
import Login from './pages/login/login';
import NotFound404 from './pages/404';

import TransactionsReport from './pages/reports/transactions_report';
import Reports from './pages/reports/reports';
import CardFees from './pages/fees/cardFees';
import Approvals from './pages/approvals/approvals';
import Affiliates from './pages/affiliate/affiliates';
import SystemUsers from './pages/system/system_users';
import System from './pages/system/system';

import { handleTokenExpiration } from './services/token';
import PendingIncomingIbanRequests from './pages/approvals/pending_incoming_iban_requests';
import PendingGatewayCardDepositRequests from './pages/approvals/pending_gateway_card_deposit_requests';
import PendingWithdrawalRequests from './pages/approvals/pending_withdrawal_requests';
import Fees from './pages/fees/fees';
import PhysicalCardOrders from './pages/cards/physical-card-orders';
import Logs from './pages/logs/logs';

const AuthenticatedRoute = ({ element, onlyAdmin = true }) => {
	const token = localStorage.getItem('token');

	useEffect(() => {
		if (token) {
			handleTokenExpiration(token);
		}
	}, [token]);

	if (!token) return <Login />;

	const decodedToken = jwtDecode(token);
	const userRole = decodedToken.role;
	if (onlyAdmin && userRole !== 'admin') return <NotFound404 />;

	return element;
};

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<AuthenticatedRoute element={<Dashboard />} onlyAdmin={false} />}></Route>

				<Route path="/users" element={<AuthenticatedRoute element={<Users />} onlyAdmin={false} />}></Route>
				<Route path="/user/view/:id" element={<AuthenticatedRoute element={<ViewUser />} />}></Route>

				<Route path="/reports" element={<AuthenticatedRoute element={<Reports />} onlyAdmin={false} />}></Route>
				<Route path="/reports/transactions" element={<AuthenticatedRoute element={<TransactionsReport />} />}></Route>
				<Route path="/reports/card-fees" element={<AuthenticatedRoute element={<CardFees />} />}></Route>

				<Route path="/approvals" element={<AuthenticatedRoute element={<Approvals />} />}></Route>
				<Route path="/approvals/incoming-iban" element={<AuthenticatedRoute element={<PendingIncomingIbanRequests />} />}></Route>
				<Route path="/approvals/gateway-card-deposits" element={<AuthenticatedRoute element={<PendingGatewayCardDepositRequests />} />}></Route>
				<Route path="/approvals/withdrawals" element={<AuthenticatedRoute element={<PendingWithdrawalRequests />} />}></Route>

				<Route path="/system" element={<AuthenticatedRoute element={<System />} />}></Route>
				<Route path="/system-users" element={<AuthenticatedRoute element={<SystemUsers />} />}></Route>

				<Route path="/affiliates" element={<AuthenticatedRoute element={<Affiliates />} />}></Route>

				<Route path="/logs" element={<AuthenticatedRoute element={<Logs />} />}></Route>
				<Route path="/fees" element={<AuthenticatedRoute element={<Fees />} />}></Route>

				<Route path="/physical-card-orders" element={<AuthenticatedRoute element={<PhysicalCardOrders />} />}></Route>

				{/* 
				<Route path="/transactions" element={<AuthenticatedRoute element={<Transactions />} />}></Route>
				<Route path="/transactions/iban-wire" element={<AuthenticatedRoute element={<WireTransactions />} />}></Route>

				<Route path="/transactions/view/:id" element={<AuthenticatedRoute element={<ViewTransaction />} />}></Route>
				<Route path="/transactions/transfers" element={<AuthenticatedRoute element={<Transfers />} />}></Route>
		
				<Route path="/messages" element={<AuthenticatedRoute element={<Messages />} />}></Route>
				<Route path="/card-top-up/report" element={<AuthenticatedRoute element={<CardTopUpReports />} />}></Route>
			
		
				<Route path="/card/fees" element={<AuthenticatedRoute element={<CardFees />} />}></Route> */}
				{/* <Route path="*" element={<Navigate to="/404" />} /> */}
			</Routes>
		</Router>
	);
}

export default App;
