import { apiRequest } from './api';

export async function viewTransactionsReportByTypeAndDate(startDate, endDate, selectedType) {
	try {
		const response = await apiRequest(`/admin/reports/transactions/${selectedType}?startDate=${startDate}&endDate=${endDate}`, 'GET');

		return {
			isSuccess: true,
			transactions: response.transactions,
			numberOfCustomers: response.numberOfCustomers,
			totalByCurrency: response.totalByCurrency,
			feesByCurrency: response.feesByCurrency,
		};
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function viewTransactionsWithFilterAPI(filters) {
	try {
		const response = await apiRequest(`/admin/transactions`, 'POST', { filters });

		return {
			isSuccess: true,
			data: response.transactions,
		};
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function viewTransactionAPI(transactionId) {
	try {
		const response = await apiRequest(`/admin/transaction/view/${transactionId}`, 'GET');

		return {
			isSuccess: true,
			data: response.transaction,
		};
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
