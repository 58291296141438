import { apiRequest } from './api';

export const getFeesAPI = async () => {
	try {
		const response = await apiRequest('/admin/fees', 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const showFeeAPI = async (id) => {
	try {
		const response = await apiRequest(`/admin/fee/${id}`, 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const editFeeAPI = async (id, cost, kind) => {
	try {
		await apiRequest(`/admin/fee/${id}`, 'PATCH', { cost, kind });

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
