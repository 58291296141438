import { useState, useEffect } from 'react';

import { PageLayout } from '../layout';
import Loader from '../loader';

import { DynamicTable } from '../../components/table';
import { CustomModal } from '../../components/modal';

import { SecondaryButton } from '../../components/secondary-button';
import { PrimaryButton } from '../../components/primary-button';

import { approveIbanTransactionAPI, viewPendingIbanDepositsAPI } from '../../services/approvals';
import { viewTransactionsWithFilterAPI } from '../../services/transactions';

const TABLE_HEAD = [
	{ key: 'creator', label: 'User', display: true },
	{ key: 'customerNumber', label: 'Customer Number', display: true },
	{ key: 'currency', label: 'Currency', display: true },
	{ key: 'amount', label: 'Amount', display: true },
	{ key: 'createdAt', label: 'Created Time', display: true },
];

export default function PendingIncomingIbanRequests() {
	const [transactions, setTransactions] = useState([]);

	const [transactionId, setTransactionId] = useState();

	const [showApproveModal, setShowApproveModal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchTransactions = async () => {
			if (!isLoading) {
				setIsLoading(true);
				const response = await viewTransactionsWithFilterAPI({ type: 'incoming_wire', status: 'pending' });
				if (response.isSuccess) {
					setTransactions(response.data);
				}
			}
			setIsLoading(false);
		};
		fetchTransactions();
	}, []);

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div className="p-10">
						<h1 className="text-xl mb-10">Incoming IBAN Transactions</h1>

						<DynamicTable
							headers={TABLE_HEAD}
							rows={transactions}
							actions={[
								{
									label: 'Approve',
									method: (id) => {
										setTransactionId(id);
										setShowApproveModal(true);
									},
								},
							]}
						/>

						<CustomModal
							isOpen={showApproveModal}
							onClose={() => setShowApproveModal(false)}
							title="Are you sure you want to approve this transaction?"
							children={
								isLoading ? (
									<Loader />
								) : (
									<div className="flex justify-center mt-5">
										<PrimaryButton
											text="Approve"
											onClick={async () => {
												if (!isLoading) {
													setIsLoading(true);
													await approveIbanTransactionAPI(transactionId);
													setShowApproveModal(false);
												}
												setIsLoading(false);
												window.location.reload();
											}}
											className="p-5 me-2"
										/>
										<SecondaryButton text="Cancel" onClick={() => setShowApproveModal(false)} className="p-5" />
									</div>
								)
							}
						/>
					</div>
				)
			}
		/>
	);
}
