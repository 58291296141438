import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (decodedToken) => {
	if (!decodedToken || !decodedToken.exp) {
		return true;
	}

	const currentTime = Math.floor(Date.now() / 1000);
	return decodedToken.exp < currentTime;
};

export const handleTokenExpiration = (token) => {
	if (token) {
		const decodedToken = jwtDecode(token);
		const isTokenExpiredResponse = isTokenExpired(decodedToken);

		if (isTokenExpiredResponse) {
			localStorage.clear();
			window.open('/', '_self');
		} else {
			const expirationDuration = 30 * 60; // 30 minutes in seconds
			const currentTime = Math.floor(Date.now() / 1000);
			const timeUntilExpiration = decodedToken.exp - currentTime;

			setTimeout(
				() => {
					localStorage.clear();
					window.open('/', '_self');
				},
				Math.min(expirationDuration, timeUntilExpiration) * 1000
			);
		}
	}
};
