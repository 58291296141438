import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DynamicTable } from '../../components/table';
import { PageLayout } from '../layout';
import { getUsersAPI } from '../../services/users';
import Loader from '../loader';
import { jwtDecode } from 'jwt-decode';
import { formatDate } from '../../utils/functions/date';
import { PrimaryButton } from '../../components/primary-button';
import { exportToExcel } from '../../utils/functions/excel_export';

const TABLE_HEAD = [
	{ key: 'user.fullName', label: 'Full Name', display: true },
	{ key: 'user.customerNumber', label: 'Customer Number', display: true },
	{ key: 'user.email', label: 'Email', display: true },
	{ key: 'user.kycLevel', label: 'KYC Level', display: true },
	{ key: 'commissions.apple_google_pay', label: 'Apple/Google Pay', display: true },
	{ key: 'commissions.atm_withdraw', label: 'ATM Withdraw', display: true },
	{ key: 'commissions.card_deopsit', label: 'Card Deposits', display: true },
	{ key: 'commissions.card_monthly', label: 'Card Monthly', display: true },
	{ key: 'commissions.paytora_monthly', label: 'Paytora Monthly', display: true },
	{ key: 'user.affiliate', label: 'Affiliate', display: true },
	{ key: 'user.country', label: 'Country', display: true },
	{ key: 'user.createdAt', label: 'Created Time', display: true },
];

export default function Users() {
	const navigate = useNavigate();

	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const token = localStorage.getItem('token');
	const decodedToken = jwtDecode(token);

	const [filters, setFilters] = useState({
		kycStatus: 'all',
		startDate: formatDate(new Date('2021')),
		endDate: formatDate(new Date()),
	});
	const fetchUsers = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await getUsersAPI(filters);
			if (response.isSuccess) {
				setUsers(response.data);
			}
		}

		setIsLoading(false);
	};
	useEffect(() => {
		fetchUsers();
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target; // Get the name and value from the event
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value, // Update only the specific filter
		}));
	};

	const exporting = () => {
		const combinedUsers = [];

		users.forEach((user) => {
			combinedUsers.push({ ...user.user, ...user.commissions });
		});

		exportToExcel([], combinedUsers, 'Users Report - ' + filters.startDate + ' - ' + filters.endDate);
	};

	const handleRowClick = (userId) => navigate(`/user/view/${userId}`);

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div className="p-10">
						<h1 className="text-3xl mb-10">Users Demo</h1>
						<div className="mb-2">
							<h1 className="text-xl mb-2">Filters</h1>
							<div className="flex items-center">
								<div className="flex flex-col">
									<p className="text-md">KYC Status</p>
									<select className="mr-2 p-[0.4rem] border" name="kycStatus" onChange={(e) => handleInputChange(e)} value={filters.kycStatus}>
										<option value="all">All</option>
										<option value="completed">Completed</option>
										<option value="none">None</option>
										<option value="partial">Partial</option>
										<option value="declined">Declined</option>
									</select>
								</div>
								<div className="flex flex-col">
									<p className="text-md">From Date</p>
									<input
										type="date"
										name="startDate"
										id="fromDate"
										value={filters.startDate}
										onChange={(e) => handleInputChange(e)}
										className="mr-2 p-1 border"
									/>
								</div>
								<div className="flex flex-col">
									<p className="text-md">To Date</p>
									<input type="date" name="endDate" id="toDate" value={filters.endDate} className="mr-2 p-1 border" />
								</div>
								<PrimaryButton text="Apply Filters" onClick={fetchUsers} className="p-5 ms-5 mt-2" />
								<PrimaryButton text="Excel Export" onClick={() => exporting()} className="p-5 ms-5 mt-2 !bg-bluePurple" />
							</div>
							<p className="mt-5">Total Users: {users.length}</p>
						</div>
						<DynamicTable headers={TABLE_HEAD} rows={users} onRowClick={decodedToken.role === 'admin' ? handleRowClick : null} />
					</div>
				)
			}
		/>
	);
}
