import { useEffect, useState } from 'react';
import { PageLayout } from '../layout';
import { DynamicTable } from '../../components/table';
import { editFeeAPI, getFeesAPI, showFeeAPI } from '../../services/fees';
import { CustomModal } from '../../components/modal';
import { PrimaryButton } from '../../components/primary-button';
import { SecondaryButton } from '../../components/secondary-button';
import Loader from '../loader';

const TABLE_HEAD = [
	{ key: 'type', label: 'Type', display: true },
	{ key: 'cost', label: 'Cost', display: true },
	{ key: 'kind', label: 'Kind', display: true },
];

const FEE_OPTIONS = [
	{ key: 'fixed', label: 'Fixed' },
	{ key: 'percentage', label: 'Percentage' },
];

export default function Fees() {
	const [fees, setFees] = useState([]);

	const [showEditModal, setShowEditModal] = useState(false);

	const [feeData, setFeeData] = useState({});

	const [selectedFeeID, setSelectedFeeID] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [errMsg, setErrMsg] = useState('');

	const getFeeDetails = async (id) => {
		const response = await showFeeAPI(id);
		setFeeData(response.data);
	};

	const approve = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await editFeeAPI(selectedFeeID, feeData.cost, feeData.kind);
			if (!response.isSuccess) {
				setErrMsg(response.error);
			} else {
				setShowEditModal(false);
			}
		}
		setIsLoading(false);
		window.location.reload();
	};

	useEffect(() => {
		const fetchData = async () => {
			if (!isLoading) {
				setIsLoading(true);
				const response = await getFeesAPI();
				if (response.isSuccess) {
					setFees(response.data);
				}
			}
			setIsLoading(false);
		};

		fetchData();
	}, []);

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div className="p-10">
						<h1 className="text-xl mb-10">Fees</h1>
						{
							<DynamicTable
								headers={TABLE_HEAD}
								rows={fees}
								actions={[
									{
										label: 'Edit',
										method: (id) => {
											setSelectedFeeID(id);
											getFeeDetails(id);
											setShowEditModal(true);
										},
									},
								]}
							/>
						}

						<CustomModal
							isOpen={showEditModal}
							onClose={() => setShowEditModal(false)}
							title="Are you sure you want to edit this fee?"
							children={
								<>
									<div className="flex flex-col">
										<p className="mb-2">
											Fee Type: <b>{feeData.type}</b>
										</p>
										<label htmlFor="type">Kind </label>
										<select
											className="mr-2 p-[0.4rem] border mb-2"
											name="kycStatus"
											onChange={(e) => setFeeData((prev) => ({ ...prev, kind: e.target.value }))}
											value={feeData.kind}
										>
											{FEE_OPTIONS.map((fee) => (
												<option value={fee.key}>{fee.label}</option>
											))}
											{/* <option value="all">All</option>
										<option value="completed">Completed</option>
										<option value="none">None</option>
										<option value="partial">Partial</option>
										<option value="declined">Declined</option> */}
										</select>
										<label htmlFor="cost">Cost </label>
										<input
											type="text"
											id="cost"
											value={feeData.cost}
											onChange={(event) => {
												setFeeData((prev) => ({ ...prev, cost: event.target.value }));
											}}
											required
											className="border-2 border-black"
										/>
									</div>
									<div className="flex justify-center mt-5">
										<PrimaryButton text="Approve" onClick={async () => await approve()} className="p-5 me-2" />
										<SecondaryButton text="Cancel" onClick={() => setShowEditModal(false)} className="p-5" />
										{/* {errMsg && <p className="text-red-500">{}</p>} */}
									</div>
								</>
							}
						/>
					</div>
				)
			}
		/>
	);
}
