import { utils, writeFile } from 'xlsx';

export const exportToExcel = (headers, rows, fileName) => {
	// Create a new workbook and a worksheet
	const wb = utils.book_new();
	const ws = utils.json_to_sheet(rows, { header: headers });

	// Append the worksheet to the workbook
	utils.book_append_sheet(wb, ws, 'Sheet1');

	// Write the workbook to a file
	writeFile(wb, `${fileName}.xlsx`);
};
