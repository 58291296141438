import { apiRequest } from './api';

export const approveIbanTransactionAPI = async (transactionId) => {
	try {
		await apiRequest(`/admin/approvals/incoming-iban/action/${transactionId}`, 'POST');

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const approveCardTransactionAPI = async (transactionId) => {
	try {
		await apiRequest(`/admin/approvals/gateway-card-deposit/action/${transactionId}`, 'POST');

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const approveWithdrawTransactionAPI = async (transactionId, data) => {
	try {
		await apiRequest(`/admin/approvals/withdrawal/action/${transactionId}`, 'POST', data);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
