import { useState } from 'react';
import numeral from 'numeral';

import { DynamicTable } from '../../components/table';
import { PageLayout } from '../layout';
import { formatDate } from '../../utils/functions/date';
import { viewTransactionsReportByTypeAndDate } from '../../services/transactions';
import { exportToExcel } from '../../utils/functions/excel_export';
import { formatTransactionType } from '../../utils/functions/formattion';

import { PrimaryButton } from '../../components/primary-button';
import Loader from '../loader';

const TABLE_HEAD = [
	{ key: 'customerNumber', label: 'Member Id', display: true },
	{ key: 'type', label: 'Type', display: true },
	{ key: 'fullName', label: 'Creator', display: true },
	{ key: 'receiver', label: 'Receiver', display: true },
	{ key: 'amount', label: 'Amount', display: true },
	{ key: 'currency', label: 'Currency', display: true },
	{ key: 'revenue', label: 'Fee', display: true },
	{ key: 'kind', label: 'Kind', display: true },
	{ key: 'paymentMethod', label: 'Payment Method', display: true },
	{ key: 'exchangedAmount', label: 'Ex Amount', display: true },
	{ key: 'exchangedCurrency', label: 'Ex Currency', display: true },
	{ key: 'status', label: 'Status', display: true },
	{ key: 'cardLastDigits', label: 'Last Digits', display: true },
	{ key: 'createdAt', label: 'Created Time', display: true },
];

const TYPES = [
	'all',
	'deposit', // +
	'card_to_card', //- to sender , + to receiver (minus fee)
	'card_to_card_receive', // +
	'swap',
	'withdrawal', // -
	'card_apply', // -
	'transfer', //- to sender , + to receiver (minus fee)
	'purchase', // + if its reversal else -
	'atm_withdrawal', // -
	'lifeup_purchase', // -
	'monthly_fee', // -
	'export_fee', // -
	'incoming_wire',
	'shipping_fee',
];

export default function TransactionsReport() {
	const [transactions, setTransactions] = useState([]);
	const [totalCustomers, setTotalCustomers] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [totalFee, setTotalFee] = useState(0);
	const [startDate, setStartDate] = useState(formatDate(new Date('2021')));
	const [endDate, setEndDate] = useState(formatDate(new Date()));
	const [selectedType, setSelectedType] = useState(TYPES[0]);

	const [isLoading, setIsLoading] = useState(false);

	const showReport = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await viewTransactionsReportByTypeAndDate(startDate, endDate, selectedType);
			if (response.isSuccess) {
				setTransactions(response.transactions);
				setTotalAmount(response.totalByCurrency);
				setTotalCustomers(response.numberOfCustomers);
				setTotalFee(response.feesByCurrency);
			}
		}
		setIsLoading(false);
	};

	const exporting = () => {
		const headerLabels = TABLE_HEAD.map((header) => header.key);

		exportToExcel(headerLabels, transactions, formatTransactionType(selectedType) + ' Report - ' + startDate + ' - ' + endDate);
	};

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div className="p-10">
						<h1 className="text-xl mb-10">Transactions Report</h1>
						<p>Total Customers: {totalCustomers}</p>
						<p>Total Amount (EUR): {numeral(totalAmount.EUR).format('0,0.00')}</p>
						<p>Total Amount (USD): {numeral(totalAmount.USD).format('0,0.00')}</p>
						<p>Total Fee: {numeral(totalFee).format('0,0.00') + ' EUR'}</p>
						<p className="mb-5">
							Date Range: {startDate} - {endDate}
						</p>
						<label htmlFor="fromDate" className="me-5">
							From Date
						</label>
						<input type="date" id="fromDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="mr-4 p-2 border" />
						<label htmlFor="toDate" className="me-5">
							To Date
						</label>
						<input type="date" id="toDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="mr-4 p-2 border" />
						<label htmlFor="type" className="me-5">
							Type
						</label>
						<select id="type" value={selectedType} className="mr-4 p-[0.68rem] border" onChange={(e) => setSelectedType(e.target.value)}>
							{TYPES.map((type) => (
								<option value={type}>{formatTransactionType(type)}</option>
							))}
						</select>
						<p className="mt-5">
							<PrimaryButton text="Show Report" onClick={showReport} className="p-5" />
							<PrimaryButton text="Export to Excel" onClick={exporting} className="p-5 ms-5" disabled={transactions.length === 0} />
						</p>
						{<DynamicTable headers={TABLE_HEAD} rows={transactions} />}
					</div>
				)
			}
		/>
	);
}
