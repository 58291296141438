import { useNavigate } from 'react-router-dom';

import { PageLayout } from '../layout';

export default function System() {
	const navigate = useNavigate();

	return (
		<div>
			<PageLayout
				pageToDisplay={
					<div className="p-10">
						<div className="p-[5rem] flex justify-center space-x-10 text-white">
							<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center" onClick={() => navigate('/fees')}>
								<h1 className="text-2xl text-center">Fees</h1>
							</div>
							<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center" onClick={() => navigate('/')}>
								<h1 className="text-2xl text-center">Limits</h1>
							</div>
							<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center" onClick={() => navigate('/logs')}>
								<h1 className="text-2xl text-center">Logs</h1>
							</div>
							<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center" onClick={() => navigate('/system-users')}>
								<h1 className="text-2xl text-center">System Users</h1>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
}
