import Modal from 'react-modal';

export const CustomModal = ({ isOpen, onClose, title, subTitle, children, width = '350px' }) => {
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			width, // Adjust the width as needed
			maxHeight: '90vh', // Allow scrolling within the viewport
			overflowY: 'auto', // Enable vertical scrolling
		},
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel={title}
			ariaHideApp={false} // This is to prevent a warning in the console
			style={customStyles}
		>
			<h2 className="text-2xl text-center">{title}</h2>
			<p className="text-xl text-center text-red-500">{subTitle}</p>

			{children}
		</Modal>
	);
};
