import { jwtDecode } from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';

export const PageLayout = ({ pageToDisplay }) => {
	const token = localStorage.getItem('token');
	const decodedToken = jwtDecode(token);

	const role = decodedToken.role;

	const roleMenuItems = {
		affiliate: [
			{ name: 'Home', path: '/' },
			{ name: 'Users', path: '/users' },
			// { name: 'Reports', path: '/reports' },
		],
		admin: [
			{ name: 'Home', path: '/' },
			{ name: 'Users', path: '/users' },
			{ name: 'Reports', path: '/reports' },
			{ name: 'Approvals', path: '/approvals' },
			{ name: 'Affiliates', path: '/affiliates' },
			{ name: 'Physical Card Orders', path: '/physical-card-orders' },
			{ name: 'System', path: '/system' },

			// { name: 'Transactions', path: '/transactions' },
			// { name: 'Wire Transactions', path: '/transactions/iban-wire' },
			// { name: 'Pending Transfers', path: '/transactions/transfers' },
			// { name: 'Pending IBAN Deposit', path: '/transactions/iban-pending' },
			// { name: 'Pending Card Deposit', path: '/transactions/card-pending' },
			// { name: 'Fees', path: '/fees' },
			// { name: 'Logs', path: '/logs' },

			// { name: 'Messages', path: '/messages' },
			// { name: 'Physical Card Orders', path: '/cards/orders' },
			// { name: 'Reports', path: '/card-top-up/report' },
			// { name: 'Card Fees', path: '/card/fees' },
		],
		// manager: ['Home', 'Users', 'Fees', 'Messages'],
	};

	const filteredMenuItems = roleMenuItems[role] || [];

	const navigate = useNavigate();
	const location = useLocation(); // Used to determine the current route

	const MenuItem = ({ itemName, path }) => {
		const isActive = location.pathname === path;
		const activeClass = isActive ? 'bg-black' : '';

		return (
			<div className={`cursor-pointer py-2 px-4 rounded ${activeClass}`} onClick={() => navigate(path)}>
				{itemName}
			</div>
		);
	};

	const handleSignOut = () => {
		localStorage.clear();
		window.open('/', '_self');
	};

	return (
		<div className="flex h-screen">
			<div className="w-1/6 bg-purple text-white h-screen overflow-y-auto flex flex-col justify-between">
				<div className="p-4">
					<div className="flex items-center mt-5">
						<img src={'/assets/dashboard/user_profile.svg'} alt="Profile" className="w-8 h-8 rounded-full text-white me-5" />
						<span>{decodedToken.email}</span>
					</div>
					<div className="mt-10">
						{filteredMenuItems.map((item) => (
							<MenuItem key={item.name} itemName={item.name} path={item.path} />
						))}
					</div>
				</div>
				<div className="p-5">
					<button className="bg-black text-white py-2 px-4 rounded w-full mt-auto" onClick={handleSignOut}>
						Log Out
					</button>
				</div>
			</div>
			<div className="flex-1 bg-white overflow-y-auto">{pageToDisplay}</div>
		</div>
	);
};
