import { apiRequest } from './api';

export const getUsersAPI = async (filters) => {
	try {
		const response = await apiRequest('/admin/users', 'POST', { filters });

		return { isSuccess: true, data: response.users };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const viewUserAPI = async (userId) => {
	try {
		const response = await apiRequest(`/admin/user/view/${userId}`, 'GET');

		return { isSuccess: true, userData: response.userData };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const resetPincodeAPI = async (userId) => {
	try {
		await apiRequest('/admin/user/reset-pincode/' + userId, 'PUT', null);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const ubanSDKUserAPI = async (sdkUserId) => {
	try {
		await apiRequest('/admin/user/uban/' + sdkUserId, 'POST', null);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const updateUserStatusAPI = async (userId, activate) => {
	try {
		await apiRequest('/admin/user/status/' + userId, 'PUT', { activate });

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const updateAffiliateAPI = async (userId, affiliateId) => {
	try {
		await apiRequest('/admin/user/affiliate', 'PUT', { userId, affiliateId });

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const sendEmailToUserAPI = async (data) => {
	try {
		await apiRequest(`/admin//user/send-email`, 'POST', data);

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
