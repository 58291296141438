import { useEffect, useState } from 'react';
import { PageLayout } from '../layout';
import { DynamicTable } from '../../components/table';
import { getLogsAPI } from '../../services/logs';

const TABLE_HEAD = [
	{ key: 'createdAt', label: 'Created Time', display: true },
	{ key: 'user', label: 'User', display: true },
	{ key: 'status', label: 'Status', display: true },
	{ key: 'type', label: 'Type', display: true },
	{ key: 'path', label: 'Path', display: true },
	{ key: 'error', label: 'Error', display: true },
	{ key: 'comment', label: 'Comment', display: true },
	{ key: 'requestBody', label: 'Request Body', display: true },
];

export default function Logs() {
	const [logs, setLogs] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getLogsAPI();
			if (response.isSuccess) {
				setLogs(response.data);
			}
		};

		fetchData();
	}, []);

	return (
		<PageLayout
			pageToDisplay={
				<div className="p-10">
					<h1 className="text-xl mb-10">Logs</h1>
					{<DynamicTable headers={TABLE_HEAD} rows={logs} />}
				</div>
			}
		/>
	);
}
