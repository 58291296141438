// Login.js
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../../pages/loader';

import { Input } from '../../components/text-input';
import { PrimaryButton } from '../../components/primary-button';
import { Header } from '../../components/header';

import { loginSchema } from '../../utils/validations/user-schema';

import { loginAPI } from '../../services/auth';

export default function Login() {
	const navigate = useNavigate();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const [errorMsg, setErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const login = async (event) => {
		event.preventDefault();
		const checkInput = loginSchema.safeParse({
			email: username,
			password,
		});

		if (checkInput.error) {
			checkInput.error && checkInput.error.issues.length > 0
				? setErrorMsg(checkInput.error.issues[0].message)
				: setErrorMsg('Error occurred while trying to login!');
			return;
		}

		if (!isLoading) {
			setIsLoading(true);

			const response = await loginAPI(username, password);

			if (!response.isSuccess) {
				setErrorMsg(response.error);
			} else {
				localStorage.setItem('token', response.token);
				setErrorMsg('');
				navigate(0);
			}
			setIsLoading(false);
		}
	};

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<Header />

			<form onSubmit={login}>
				<div className="flex flex-col items-center mt-10 p-5">
					<h1 className=" text-center text-3xl">Admin Panel</h1>
					<img src="/assets/logo.png" alt="logo" className="xsm:w-full w-1/3 mt-10 mb-10" />
					<Input
						onChange={(value) => {
							setUsername(value);
						}}
						placeholder="USER NAME"
						type="text"
						value={username}
						className="text-white placeholder:text-white"
					/>
					<Input
						placeholder={'Password'}
						value={password}
						type={'password'}
						onChange={(value) => {
							setPassword(value);
						}}
						className="text-white placeholder:text-white"
					/>
					{errorMsg && <div className="text-error mb-5 font-bold">{errorMsg}</div>}
					<PrimaryButton text={'LOGIN'} className={'p-10'} onClick={login} />
				</div>
			</form>
		</div>
	);
}
