import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PageLayout } from '../layout';
import { DynamicTable } from '../../components/table';
import { exportToExcel } from '../../utils/functions/excel_export';
import { formatDate } from '../../utils/functions/date';
import { PrimaryButton } from '../../components/primary-button';
import { viewCardFees } from '../../services/cards';
import Loader from '../loader';

const TABLE_HEAD = [
	{ key: 'roundedAmount', label: 'Amount', display: true },
	// { key: 'roundedAmount', label: 'Rounded Amount', display: true },
	{ key: 'customerNumber', label: 'Customer Number', display: true },
	{ key: 'customerName', label: 'Customer Name', display: true },
	{ key: 'type', label: 'Type', display: true },
	{ key: 'transactionAmount', label: 'Transaction Amount', display: true },
	{ key: 'merchantName', label: 'Merchant Name', display: true },
	{ key: 'description', label: 'Description', display: true },
	{ key: 'verestroId', label: 'Verestro Transaction Id', display: true },
	{ key: 'createdAt', label: 'Created At', display: true },
];
const DESCRIPTIONS = [
	'All',
	'POS transaction domestic Poland (net)',
	'POS transaction interregional (net)',
	'POS transaction intraregional (net)',
	'e-Commerce transaction domestic Poland (net)',
	'e-Commerce transaction interregional Poland (net)',
	'e-Commerce transaction intraregional (net)',
	'Apple/Google Pay debit transaction cost (remote and contactless)',
	'ATM cash withdrawal domestic Poland (net)',
	'ATM cash withdrawal interregional (net)',
	'ATM cash withdrawal intraregional (net)',
	'3D SMS Approval',
	'IBAN for each incoming transaction',
];
export default function CardFees() {
	const [cardFees, setCardFees] = useState([]);

	const [selectedDescription, setSelectedDescription] = useState(DESCRIPTIONS[0]);

	const [totalTransactionAmount, setTotalTransactionAmount] = useState(0);
	const [totalRoundedAmount, setTotalRoundedAmount] = useState(0);
	// const [totalNonRoundedAmount, setTotalNonRoundedAmount] = useState(0);

	const [startDate, setStartDate] = useState(formatDate(new Date('2021')));
	const [endDate, setEndDate] = useState(formatDate(new Date()));

	const [isLoading, setIsLoading] = useState(false);

	const showReport = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await viewCardFees(startDate, endDate, selectedDescription != 'All' ? { description: selectedDescription } : null);

			if (response.isSuccess) {
				setCardFees(response.cardFees);
				setTotalTransactionAmount(response.totalTransactionAmount);
				setTotalRoundedAmount(response.totalRoundedAmount);
				// setTotalNonRoundedAmount(response.totalNonRoundedAmount);
			}
		}
		setIsLoading(false);
	};

	const exporting = () => {
		const headerLabels = TABLE_HEAD.map((header) => header.key);

		exportToExcel(headerLabels, cardFees, 'Cards Fee Report - ' + startDate + ' - ' + endDate);
	};

	return (
		<PageLayout
			pageToDisplay={
				isLoading ? (
					<Loader />
				) : (
					<div className="p-10">
						<h1 className="text-xl mb-10">Card Fees</h1>
						<p>Total Transaction Amount: {totalTransactionAmount}€</p>
						<p className="mb-5">Total Rounded Commission Amount: {totalRoundedAmount}€</p>
						{/* <p>Total Non Rounded Comission Amount: {totalNonRoundedAmount}€</p> */}
						<p className="mb-5">
							Date Range: {startDate} - {endDate}
						</p>
						<label htmlFor="fromDate" className="me-5">
							From Date
						</label>
						<input type="date" id="fromDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="mr-4 p-2 border" />
						<label htmlFor="toDate" className="me-5">
							To Date
						</label>
						<input type="date" id="toDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="mr-4 p-2 border" />

						<label htmlFor="type" className="me-5">
							Description
						</label>
						<select
							id="type"
							className="mr-4 p-[0.68rem] border"
							value={selectedDescription}
							// defaultValue={{ value: selectedDescription, label: selectedDescription }}
							onChange={(e) => setSelectedDescription(e.target.value)}
						>
							{DESCRIPTIONS.map((type) => (
								<option value={type}>{type}</option>
							))}
						</select>

						<p className="mt-5">
							<PrimaryButton text="Show Report" onClick={showReport} className="p-5" />
							<PrimaryButton text="Export to Excel" onClick={exporting} className="p-5 ms-5" disabled={cardFees.length === 0} />
						</p>

						{<DynamicTable headers={TABLE_HEAD} rows={cardFees} />}
					</div>
				)
			}
		/>
	);
}
