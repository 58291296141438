import { apiRequest } from './api';

export const getAffiliatesAPI = async () => {
	try {
		const response = await apiRequest('/admin/affiliates', 'GET', null);

		return { isSuccess: true, data: response.data };
	} catch (error) {
		console.log(error, 'the api eror');
		return { isSuccess: false, error: error.message };
	}
};

export const addAffiliateAPI = async (data) => {
	console.log('data ', data);
	try {
		await apiRequest('/admin/affiliate', 'POST', data);

		return { isSuccess: true };
	} catch (error) {
		console.log(error, 'the api eror');
		return { isSuccess: false, error: error.message };
	}
};
