import { logoutUser } from '../etc/functions/user';
import { PrimaryButton } from './primary-button';

export const Header = ({}) => {
	const token = localStorage.getItem('token');

	return (
		<div className="border-b p-2 border-">
			<div className="p-3 flex justify-between items-center">
				<a href="/">
					<img src="/assets/logo.png" alt="logo" className="w-64" />
				</a>
				{token && <PrimaryButton onClick={() => logoutUser()} text="Logout" className="p-5" />}
			</div>
		</div>
	);
};
