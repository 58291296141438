import { useNavigate } from 'react-router-dom';

import { PageLayout } from '../layout';

export default function Approvals() {
	const navigate = useNavigate();

	return (
		<div>
			<PageLayout
				pageToDisplay={
					<div className="p-10">
						<div className="p-[5rem] flex justify-center space-x-10 text-white">
							<div
								className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center"
								onClick={() => navigate('/approvals/incoming-iban')}
							>
								<h1 className="text-2xl text-center">Incoming IBAN Transfers</h1>
							</div>
							<div
								className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center"
								onClick={() => navigate('/approvals/gateway-card-deposits')}
							>
								<h1 className="text-2xl text-center">Gateway Card Deposits</h1>
							</div>
							<div className="w-[22rem] p-10 bg-purple flex flex-col items-center justify-center" onClick={() => navigate('/approvals/withdrawals')}>
								<h1 className="text-2xl text-center">Withdrawals</h1>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
}
