import { Card, Typography } from '@material-tailwind/react';

import { PrimaryButton } from '../components/primary-button';

export function DynamicTable({ headers, rows, actions, onRowClick }) {
	function getNestedValue(obj, keyPath) {
		return keyPath.split('.').reduce((acc, key) => acc && acc[key], obj);
	}

	return (
		<Card className="h-full w-full overflow-scroll">
			<table className="w-full min-w-max table-auto text-left">
				<thead>
					<tr>
						{headers.map(
							(header) =>
								header.display && (
									<th key={header.key} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
										<Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
											{header.label}
										</Typography>
									</th>
								)
						)}
						{actions && <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Actions</th>}
					</tr>
				</thead>
				<tbody>
					{rows.map((row, rowIndex) => (
						<tr key={rowIndex} onClick={() => onRowClick && onRowClick(row.id ?? row._id)} className="cursor-pointer">
							{headers.map(
								(header, cellIndex) =>
									header.display && (
										<td key={cellIndex} className="p-4 border-b border-blue-gray-50 break-words overflow-hidden whitespace-normal max-w-xs">
											<Typography variant="small" color="blue-gray" className="font-normal">
												{header.render ? header.render(getNestedValue(row, header.key), row) : getNestedValue(row, header.key)}
											</Typography>
										</td>
									)
							)}
							{actions && (
								<td className="flex flex-col p-4 border-b border-blue-gray-50">
									{actions.map((action, actionIndex) => (
										<PrimaryButton
											text={action.label}
											onClick={() => (row._id ? action.method(row._id) : action.method(row.id))}
											className="p-5 mb-2"
											key={actionIndex}
										/>
									))}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</table>
		</Card>
	);
}
