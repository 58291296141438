export const Input = ({ maxLength, value, placeholder, type, onChange, className }) => {
  return (
    <input
      maxLength={maxLength}
      value={value}
      className={`bg-black w-full rounded-xl py-2 px-4  max-w-md flex flex-col text-black placeholder:text-black  text-center mb-5 ${className}`}
      placeholder={placeholder}
      type={type}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
