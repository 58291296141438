import { apiRequest } from './api';

export const viewCardFees = async (startDate, endDate, filter) => {
	try {
		const response = await apiRequest(`/admin/reports/card-fees?startDate=${startDate}&endDate=${endDate}`, 'POST', { filter });

		return {
			isSuccess: true,
			cardFees: response.cardFees,
			totalTransactionAmount: response.totalTransactionAmount,
			totalRoundedAmount: response.totalRoundedAmount,
			totalNonRoundedAmount: response.totalNonRoundedAmount,
		};
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const getPhysicalCardOrdersAPI = async () => {
	try {
		const response = await apiRequest(`/admin/cards/orders`, 'GET');

		return {
			isSuccess: true,
			orders: response.data,
		};
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const deliverCardOrderAPI = async (orderId) => {
	try {
		await apiRequest(`/admin/cards/orders/deliver/${orderId}`, 'PUT');

		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
